<template>
  <main>
    <div class="container">
      <h1>Контакты</h1>
      <div class="row">
        <div class="col-md-8 col-lg-7">
          <hr>
          <p>Внимание! По&nbsp;вопросам активации сертификатов, пожалуйста, обращайтесь в&nbsp;Отдел внутренних коммуникаций по&nbsp;адресу: <a href="mailto:Grabovyh.A.K@eksmo.ru">Grabovyh.A.K@eksmo.ru</a> к&nbsp;Анне Грабовых, специалисту по&nbsp;внутренним коммуникациям и&nbsp;корпоративной культуре.</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
}
</script>

<script>
export default {
  name: 'Contacts',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--inner')
  }
}
</script>
