<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-10">
          <h1>Дорогие друзья!</h1>
          <p class="lead">Поздравляем вас с&nbsp;победой в&nbsp;Премии <span class="colored">&laquo;Признание 2018&raquo;</span>!</p>
          <hr>
          <p>На&nbsp;церемонии награждения вам подарили сертификат, которым вы&nbsp;можете воспользоваться здесь. Номинал сертификата вы&nbsp;видите в&nbsp;правом верхнем углу экрана.</p>
          <ul>
            <li>Перейдите во&nbsp;вкладку &laquo;Выберите подарок&raquo;</li>
            <li>Щелкните по&nbsp;интересующему вас бренду</li>
            <li>Ваш выбор сделан! Сертификат отправлен на&nbsp;указанный вами <nobr>e-mail</nobr>.</li>
            <li>Обратите внимание на&nbsp;условия использования сертификата.</li>
          </ul>
          <p class="red">Внимание! По&nbsp;вопросам активации сертификатов, пожалуйста, обращайтесь в&nbsp;Отдел внутренних коммуникаций по&nbsp;адресу: <a href="mailto:Grabovyh.A.K@eksmo.ru">Grabovyh.A.K@eksmo.ru</a> к&nbsp;Анне Грабовых, специалисту по&nbsp;внутренним коммуникациям и&nbsp;корпоративной культуре.</p>
          <router-link class="button button--rounded button--large" :to="{ name: 'Catalog' }">Выбрать подарок</router-link>
          <br>
          <br>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
}
</script>

<script>
import 'base/components/Cabinet'

export default {
  name: 'Cabinet',
  mounted: function () {
    this.$store.commit('app/updatePageClass', 'page--cabinet')
  }
}
</script>

<style lang="css" scoped>
  .red {
    color: #c00;
  }

  .bold,
  b {
    font-weight: bold;
  }

  .italic,
  em {
    font-style: italic;
  }

  .accent {
    color: var(--accent-color);
  }

  p {
    color: #333;
  }

  .buttons {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: space-between;
    width: 60%;
  }

  .buttons a {
    display: flex;
    text-align: center;
    font-size: 20px;
    padding: 1em;
    width: 49%;
    margin-bottom: 15px;
  }

  .buttons a span {
    display: block;
    margin: auto;
    line-height: 1.2;
  }

  @media (max-width: 991px) {
    .buttons {
      flex-wrap: wrap;
      width: auto;
    }

    .buttons a {
      width: 49%;
    }
  }

  @media (max-width: 575px) {
    .buttons {
      display: block;
    }

    .buttons a {
      width: auto;
    }
  }
</style>
